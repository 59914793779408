import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Grid } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-top: 7rem;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 1rem;
  }
`
export const Wrapper = styled(Container)``
export const TitleWrapper = styled.div`
  h1 {
    text-align: center;
    max-width: 750px;
    margin: auto;
  }
  padding-bottom: 4.5rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 2rem;
  }
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 145px;
  }
`
export const Button = styled(CustomButton)``
export const GridCard = styled(Grid)`
  width: 100%;
`
export const Card = styled.div`
  width: 100%;

  &.move {
    ${({ theme }) => theme.breakpoints.up("md")} {
      position: absolute;
      top: 80px;
    }
  }
`
export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
