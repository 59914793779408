import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding-top: 7rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 1rem;
  }
`
export const Wrapper = styled(Container)``
export const TitleWrapper = styled.div`
  //margin-bottom: 4rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: 0;
  }
`
export const DescriptionContainer = styled.div`
  max-width: 426px;
  margin: 5.5rem 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 0;
    margin-top: 2rem;
  }
`
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`
export const ValuesContainer = styled.div`
  max-width: 660px;
  margin-top: 4rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: 0;
  }
`
export const Values = styled.h2`
  color: ${({ theme }) => theme.palette.text.secondary};
  strong {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: "SignifierMedium", sans-serif;
    font-weight: 500;
  }
`
