import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"
import CustomLink from "../../components/custom-link/custom-link.component"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding-top: 7rem;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 1rem;
  }
`
export const Wrapper = styled(Container)``
export const TitleWrapper = styled.div`
  max-width: 893px;
  margin-top: 0;
  margin-bottom: 5rem;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 0;
  }
`
export const DownloadWrapper = styled.div`
  padding-left: 3rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 2rem;
    padding-left: 0;
  }
`
export const Description = styled.p`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 1.3rem;
`
export const DownloadLink = styled(CustomLink)`
  padding: 0.595rem 1.7rem;
  border-radius: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: 500;
  margin-left: auto;
  background-color: white;
  border: 2px solid rgba(164, 157, 188, 0.2);
  color: ${({ theme }) => theme.palette.primary.main};
  transition: all 0.3s ease-in-out !important;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.light};
    background-color: ${({ theme }) => theme.palette.primary.main};
    svg {
      g {
        stroke: ${({ theme }) => theme.palette.primary.light};
        fill: ${({ theme }) => theme.palette.primary.light};
      }
    }
  }
`
export const ServicesContainer = styled.div``
