import React, { useState } from "react"
import { Slide } from "@mui/material"

import * as S from "./card-leader.styles"
import useMousePosition from "../../hooks/use-mouse-position/use-mouse-position"
import { CustomCursor } from "./card-leader.styles"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />
})

const CardLeader = ({ image, name, jobPosition, biography }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { x, y } = useMousePosition()
  return (
    <S.CardContainer>
      <S.ImageContainer onClick={handleOpen}>
        <S.Image img={image} arPaddingPercentage={150} />
        <S.ImageOverlay className="overlay">
          <S.Name>{name}</S.Name>
          <S.JobPosition>{jobPosition}</S.JobPosition>
        </S.ImageOverlay>
      </S.ImageContainer>
      {/*<S.CustomDialog*/}
      {/*  fullScreen*/}
      {/*  open={open}*/}
      {/*  onClose={handleClose}*/}
      {/*  TransitionComponent={Transition}*/}
      {/*>*/}
      {/*  <S.BgImage*/}
      {/*    img={image}*/}
      {/*    onClick={handleClose}*/}
      {/*    // className={open && "hovered"}*/}
      {/*  >*/}
      {/*    <S.CustomCursor style={{ left: `${x}px`, top: `${y}px` }} />*/}
      {/*    <S.ContentContainer>*/}
      {/*      <S.Name>{name}</S.Name>*/}
      {/*      <S.JobPosition>{jobPosition}</S.JobPosition>*/}
      {/*      /!*<S.Biography>{biography}</S.Biography>*!/*/}
      {/*    </S.ContentContainer>*/}
      {/*  </S.BgImage>*/}
      {/*</S.CustomDialog>*/}
    </S.CardContainer>
  )
}

export default CardLeader
