import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)`
  padding-top: 0;
  padding-bottom: 1.5rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
`
export const Wrapper = styled(Container)``
export const Title = styled.h2`
  //margin-top: 0;
  text-align: center;
  //font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.light};
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 3.5rem;
  }
`

export const CardContainer = styled.div`
  height: 100% !important;
  ${({ theme }) => theme.breakpoints.up("md")} {
    border-right: 2px solid #3c3c3c;
    padding: 3rem 2rem;
    &.last {
      border-right: none;
    }
    &.first {
      padding: 3rem 0;
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    border-bottom: 2px solid #3c3c3c;
    &.last {
      border-bottom: none;
    }
  }
`
