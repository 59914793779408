import React from "react"
import { Grid } from "@mui/material"

import * as S from "./card-technology.styles"
import { height } from "@mui/system"

const CardTechnology = ({ title, description, brands }) => {
  return (
    <S.CardContainer>
      {title && <S.Title>{title}</S.Title>}
      {description && <S.Description>{description}</S.Description>}
      <Grid container spacing={2}>
        {brands.map(({ image }, index) => (
          <Grid item xs={6} key={`img-${index}`}>
            {image && (
              <S.Image
                img={image}
                alt="brand"
                height={
                  title === "Static Site Generator" && index === 1
                    ? "55px"
                    : "45px"
                }
              />
            )}
          </Grid>
        ))}
      </Grid>
    </S.CardContainer>
  )
}

export default CardTechnology
