import styled from "styled-components"
import CustomImage from "../../components/custom-image/custom-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
`
export const Wrapper = styled(Container)`
  background-color: #2a2b2e;
  padding: 1em;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 4em;
  }
`
export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.light};
  margin-bottom: 3rem;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 1.5rem;
  }
`
export const TestimonialWrapper = styled.div``
export const Description = styled.h3`
  color: ${({ theme }) => theme.palette.primary.light};
  text-align: center;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: normal;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 890px;
    margin: auto;
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    line-height: ${({ theme }) => theme.typography.pxToRem(40)};
  }
`
export const AuthorDescription = styled.div``
export const AuthorImage = styled(CustomImage)`
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  img {
    border-radius: 50%;
  }
`
export const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1em;
  margin-top: 2rem;
`

export const AuthorDetail = styled.p`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(25)};
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0;
  font-weight: 400;

  &.author {
    font-weight: 500;
  }
`
