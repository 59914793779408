import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding-top: 7rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 1rem;
  }
`
export const Wrapper = styled(Container)``
export const TitleWrapper = styled.div`
  max-width: 600px;
  margin-top: 0;
  margin-bottom: 7rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
`
