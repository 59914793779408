import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"

export const CardContainer = styled.div`
  width: 100%;
  height: 100% !important;

  // ${({ theme }) => theme.breakpoints.down("md")} {
  //   display: flex;
  //   flex-direction: column;
  // }
`
export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary.light};
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
    line-height: normal;
  }
`
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 310px;
  }
`
export const Image = styled(CustomImage)`
  margin-bottom: 0.5rem;
  img {
    height: ${props => props.height};
    object-fit: contain !important;
    ${({ theme }) => theme.breakpoints.down("md")} {
      height: 30px;
    }
  }
`

export const ImageContainer = styled.div``
