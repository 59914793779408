import styled from "styled-components"
import { Grid } from "@mui/material"
import { ArrowForward, ArrowBack } from "@mui/icons-material"

export const ItemContainer = styled.div`
  padding-top: 2em;
  padding-bottom: 2em;
  border-top: 1px solid rgba(0, 0, 0, 0.4);

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 6em;
    padding-bottom: 6em;
  }

  &:hover {
    .circle-btn {
      background-color: black;

      svg {
        color: white;
      }
    }
  }
`

export const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(20)};
  font-weight: 400;
  margin: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  }
`

export const Title = styled.h2`
  margin: 0;
  margin-top: 16px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(28)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  font-family: ${({ theme }) => theme.fonts.secondary};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(40)};
    line-height: ${({ theme }) => theme.typography.pxToRem(44)};
  }
`

export const IconGrid = styled(Grid)`
  display: none;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    justify-content: flex-end;
  }
`

export const Link = styled.span`
  display: flex;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  border-radius: 50%;
  transition: all 0.3s ease;

  &:hover {
    background-color: black;
    svg {
      color: white;
    }
  }
`

export const ArrowForwardIcon = styled(ArrowForward)`
  color: black;
`
