import styled from "styled-components"

export const CardContainer = styled.div`
  width: 100%;
  height: 100% !important;
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 80px !important;
    height: 60px !important;
    path {
      stroke: ${props => props.color} !important;
    }
  }
`

export const ServiceName = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  letter-spacing: ${({ theme }) => theme.typography.pxToRem(0.1)};
  color: ${({ theme }) => theme.palette.primary.light};
  margin-top: 1.5rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    text-align: center;
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
    line-height: normal;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`
export const Description = styled.p`
  max-width: 320px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.text.secondary};
  ${({ theme }) => theme.breakpoints.down("md")} {
    text-align: center;
  }
`
export const LottieContainer = styled.div`
  background: linear-gradient(
    135deg,
    transparent 50%,
    ${props => props.color} 50%
  );
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`
