import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"

export const BannerContainer = styled.div`
  margin-bottom: 5rem;
  padding-top: 2.5rem;
  border-top: 1px solid ${props => props.borderColor};
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 2.5rem;
  }
`
export const ServiceContainer = styled.div`
  display: flex;
  column-gap: 2rem;
  align-items: center;
  svg {
    width: 80px !important;
    height: 60px !important;
    path {
      stroke: ${props => props.color} !important;
    }
  }
`
export const Logo = styled(CustomImage)`
  width: 64px;
  height: 64px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }
`
export const ServiceName = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
`
export const ServiceItemContainer = styled.div`
  max-width: 310px;
`
export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  letter-spacing: ${({ theme }) => theme.typography.pxToRem(0.1)};
  font-weight: normal;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    line-height: normal;
  }
`
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`
export const LottieContainer = styled.div`
  background: linear-gradient(
    135deg,
    transparent 50%,
    ${props => props.color} 50%
  );
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`
