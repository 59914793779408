import React from "react"
import { Grid } from "@mui/material"
import StatisticBox from "../../components/statistic-box/statistic-box.component"
import parse from "html-react-parser"
import FadeTitle from "../../components/fade-title/fade-title.component"

import * as S from "./about-hero.styles"

const AboutHero = ({ title, description, statistics, valuesDescription }) => {
  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        {title && (
          <S.TitleWrapper>
            <FadeTitle title={title} />
          </S.TitleWrapper>
        )}
        {description && (
          <S.DescriptionContainer>
            <S.Description>{description}</S.Description>
          </S.DescriptionContainer>
        )}
        <Grid container spacing={2} justifyContent="center">
          {statistics?.map((item, index) => (
            <Grid item xs={6} md={3} key={`${index} - ${item.title}`}>
              <StatisticBox {...item} />
            </Grid>
          ))}
        </Grid>
        {valuesDescription && (
          <S.ValuesContainer>
            <S.Values className="signifierMedium">
              {parse(valuesDescription)}
            </S.Values>
          </S.ValuesContainer>
        )}
      </S.Wrapper>
    </S.Section>
  )
}

export default AboutHero
