import React from "react"
import { Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import HoverVideo from "../../components/hover-video/hover-video.component"
import FadeTitle from "../../components/fade-title/fade-title.component"

import * as S from "./work-cards.styles"

const WorkCards = ({ title }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      allWpCaseStudy(sort: { fields: date, order: DESC }) {
        nodes {
          caseStudiesBuilder {
            coverCard {
              videoUrl
              image {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                  }
                }
              }
            }
          }
          caseStudyCategories {
            nodes {
              name
            }
          }
          uri
          slug
          title
        }
      }
    }
  `)

  const data = staticQuery?.allWpCaseStudy?.nodes
  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        <S.TitleWrapper>
          <FadeTitle title={title} />
        </S.TitleWrapper>
        <Grid container spacing={3}>
          {data?.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={`${index} - ${item?.title}`}>
              <HoverVideo
                image={item?.caseStudiesBuilder?.coverCard?.image}
                companyName={item?.title}
                caseStudyUrl={`/casos-de-estudio/${item?.slug}`}
                technologyDescription={item?.caseStudyCategories?.nodes}
                videoUrl={item?.caseStudiesBuilder?.coverCard?.videoUrl}
              />
            </Grid>
          ))}
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default WorkCards
