import React from "react"
import { Grid } from "@mui/material"
import CardTechnology from "../../components/card-technology/card-technology.component"

import * as S from "./technologies.styles"

const Technologies = ({ title, technologies }) => {
  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        {title && <S.Title className="signifierMedium">{title}</S.Title>}
        <Grid container spacing={2}>
          {technologies?.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={`${index} - ${item.technologyName}`}
            >
              <S.CardContainer
                className={
                  index + 1 === technologies?.length
                    ? "last"
                    : index === 0 && "first"
                }
              >
                <CardTechnology title={item.technologyName} {...item} />
              </S.CardContainer>
            </Grid>
          ))}
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default Technologies
