import styled from "styled-components"
import CustomButton from "../../components/custom-button/custom-button.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-top: 0;
`
export const Wrapper = styled(Container)``
export const Title = styled.h2`
  text-align: center;
  //font-weight: 500;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-bottom: 1.5rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 4rem;
  }
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 2.5rem;
  }
`
export const Button = styled(CustomButton)``
