import React from "react"
import ServiceBanner from "../../components/service-banner/service-banner.component"
import FadeTitle from "../../components/fade-title/fade-title.component"
import DownloadIcon from "../../assets/icons/download.svg"
import { customAnimation } from "../../components/fade-title/fade-title.component"
import { Grid } from "@mui/material"
import Reveal from "react-awesome-reveal"
import { isBrowser } from "../../utils"
import Services from "../../../static/documents/aftershock-services.pdf"
import * as S from "./service-description.styles"

const ServiceDescription = ({ title, services }) => {
  const handleClick = () => {
    if (isBrowser()) {
      window.gtag("event", "services_pdf_download")
    }
  }
  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          <Grid item xs={12} md={8}>
            {title && (
              <S.TitleWrapper>
                <FadeTitle title={title} />
              </S.TitleWrapper>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Reveal keyframes={customAnimation} triggerOnce={true}>
              <S.DownloadWrapper>
                <S.Description>
                  Descarga nuestra declaración de capacidades:
                </S.Description>
                <S.DownloadLink
                  onClick={handleClick}
                  href={Services}
                  download="aftershock-services"
                >
                  <DownloadIcon />
                  &nbsp; Descargar PDF
                </S.DownloadLink>
              </S.DownloadWrapper>
            </Reveal>
          </Grid>
        </Grid>
        <S.ServicesContainer>
          {services?.map((item, index) => (
            <ServiceBanner {...item} key={index - item.serviceName} />
          ))}
        </S.ServicesContainer>
      </S.Wrapper>
    </S.Section>
  )
}

export default ServiceDescription
