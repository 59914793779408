import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"
import { Dialog, Slide } from "@mui/material"
import CustomButton from "../custom-button/custom-button.component"
import CustomBgImage from "../custom-bg-image/custom-bg-image.component"
import cursor from "../../assets/icons/plus-icon.svg"

export const CardContainer = styled.div`
  //cursor: none;
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
`

export const ImageContainer = styled(CustomButton)`
  display: block;
  position: relative;
  width: 100%;
  padding: 0;
  cursor: default;
`

export const Image = styled(CustomImage)`
  overflow: hidden;
  display: block;
`

export const ImageOverlay = styled.div`
  background-color: RGBA(28, 29, 31, 0.4);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease all;
`

export const Name = styled.h3`
  color: ${({ theme }) => theme.palette.text.light};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
`

export const JobPosition = styled.p`
  color: ${({ theme }) => theme.palette.text.light};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  text-align: center;
  font-weight: 400;
  margin: 0;
`

export const CustomDialog = styled(Dialog)``
export const CardDescriptionContainer = styled.div`
  display: flex;

  height: 100%;
  width: 100%;
`
export const BgImage = styled(CustomBgImage)`
  height: 100vh;
  width: 100vw;
  cursor: none;
  &:hover {
    svg {
      transform: rotate(135deg);
      transition: transform 0.5s;
    }
  }
`
export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Biography = styled.p`
  max-width: 800px;
`
export const CustomSlide = styled(Slide)`
  background-color: rgb(28, 29, 31, 0.4);
`
export const CustomCursor = styled(cursor)`
  display: none;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: revert;
    width: 100px;
    height: 100px;
    position: fixed;
    z-index: 99;
  }
`
