import styled from "styled-components"
import CustomLink from "../custom-link/custom-link.component"
import CustomBgImage from "../custom-bg-image/custom-bg-image.component"

export const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  //position: relative;
  video {
    height: 600px;
    ${({ theme }) => theme.breakpoints.down("md")} {
      height: 400px;
    }
  }
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    //right: 10px;
  }
`
export const Link = styled(CustomLink)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`
export const BgImage = styled(CustomBgImage)`
  //transition: opacity 0.3s ease-in-out;
  //opacity: ${props => props.opacity};
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 600px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    transition: 0.5s;
    height: 400px;
    margin: auto;
  }
`
export const ContentContainer = styled.div`
  padding: 3rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1rem;
  }
`
export const CompanyName = styled.h3`
  color: ${({ theme }) => theme.palette.primary.light};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: 500;
  margin-bottom: 0.5rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    margin-top: 0;
  }
`
export const CategoriesContainer = styled.div`
  display: flex;
  //column-gap: 0.5rem;
`
export const Technology = styled.p`
  color: ${({ theme }) => theme.palette.primary.light};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(21)};
  margin-top: 0;
`
export const CustomVideo = styled.video`
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
`
