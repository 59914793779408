import React from "react"
import Slider from "react-slick"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import "../../../node_modules/slick-carousel/slick/slick.css"

import * as S from "./people-saying.styles"

const PeopleSaying = ({ title, testimonials }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        {title && <S.Title className="signifierMedium">{title}</S.Title>}
        <Slider {...settings}>
          {testimonials?.map(
            (
              { peopleExperience, authorImage, authorName, businessPosition },
              index
            ) => (
              <S.TestimonialWrapper key={`${index} - ${authorName}`}>
                {peopleExperience && (
                  <S.Description>{peopleExperience}</S.Description>
                )}
                <S.AuthorContainer>
                  {authorImage && (
                    <S.AuthorImage img={authorImage} alt="author" />
                  )}
                  <S.AuthorDescription>
                    {authorName && (
                      <S.AuthorDetail className="author">
                        {authorName}
                      </S.AuthorDetail>
                    )}
                    {businessPosition && (
                      <S.AuthorDetail>{businessPosition}</S.AuthorDetail>
                    )}
                  </S.AuthorDescription>
                </S.AuthorContainer>
              </S.TestimonialWrapper>
            )
          )}
        </Slider>
      </S.Wrapper>
    </S.Section>
  )
}

export default PeopleSaying
