import React from "react"
import { Grid } from "@mui/material"

import * as S from "./career-item.styles"

const CareerItem = ({ title, location }) => {
  return (
    <S.ItemContainer>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={6}>
          <S.Subtitle>Puesto</S.Subtitle>
          <S.Title>{title}</S.Title>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <S.Subtitle>Ubicación</S.Subtitle>
          <S.Title>{location}</S.Title>
        </Grid>
        <S.IconGrid item xs={12} md={1}>
          <div>
            <S.Link className="circle-btn">
              <S.ArrowForwardIcon />
            </S.Link>
          </div>
        </S.IconGrid>
      </Grid>
    </S.ItemContainer>
  )
}

export default CareerItem
