import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding-top: 4em;
  padding-bottom: 7em;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 1em;
    padding-bottom: 2em;
  }
`
export const Wrapper = styled(Container)``
export const TitleWrapper = styled.div`
  max-width: 893px;
  margin-top: 0;
  margin-bottom: 3rem;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 0;
    h1 {
      font-size: 2.5rem;
      line-height: 3.4rem;
    }
  }
`

export const Line = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.4);
`
