import React from "react"
import FadeTitle from "../../components/fade-title/fade-title.component"
import CareerItem from "../../components/career-item/career-item.component"
import { graphql, useStaticQuery } from "gatsby"
import CustomLink from "../../components/custom-link/custom-link.component"

import * as S from "./careers.styles"

const Careers = ({ title }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      allWpCareer(sort: { order: DESC, fields: date }) {
        nodes {
          title
          uri
          slug
          careers {
            location
          }
        }
      }
    }
  `)

  const careers = staticQuery.allWpCareer.nodes

  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        {title && (
          <S.TitleWrapper>
            <FadeTitle title={title} />
          </S.TitleWrapper>
        )}
        {careers?.map(({ title, careers: { location }, slug }, index) => (
          <CustomLink url={`/vacantes/${slug}`} key={`career-item-${index}`}>
            <CareerItem title={title} location={location} />
          </CustomLink>
        ))}
        <S.Line />
      </S.Wrapper>
    </S.Section>
  )
}

export default Careers
