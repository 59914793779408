import React, { useRef } from "react"
import { Grid } from "@mui/material"
import Lottie from "lottie-react"
import { cardServiceLogo } from "../../utils"

import * as S from "./service-banner.styles"

const ServiceBanner = ({ serviceName, serviceItems }) => {
  const logoValues = cardServiceLogo(serviceName)
  const lottieRef = useRef()
  const handleHoverOn = () => {
    lottieRef.current.setDirection(1)
    lottieRef.current.playSegments([0, 50], true)
  }
  const handleHoverOff = () => {
    lottieRef.current.setDirection(-1)
    lottieRef.current.playSegments(lottieRef.current, true)
  }

  return (
    <S.BannerContainer
      borderColor={logoValues.borderColor}
      onMouseEnter={handleHoverOn}
      onMouseLeave={handleHoverOff}
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <S.ServiceContainer color={logoValues.logoColor}>
            <S.LottieContainer color={logoValues.bgColor}>
              <Lottie
                lottieRef={lottieRef}
                animationData={logoValues.logo}
                autoplay={false}
                loop={false}
              />
            </S.LottieContainer>
            <S.ServiceName className="signifierMedium">
              {serviceName}
            </S.ServiceName>
          </S.ServiceContainer>
        </Grid>
        <Grid item container spacing={3} xs={12} md={6}>
          {serviceItems?.map(({ title, description }, index) => (
            <Grid item xs={12} sm={6} key={`${index} - ${title}`}>
              <S.ServiceItemContainer>
                <S.Title>{title}</S.Title>
                <S.Description>{description}</S.Description>
              </S.ServiceItemContainer>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </S.BannerContainer>
  )
}

export default ServiceBanner
