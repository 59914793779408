import React, { useState } from "react"

import * as S from "./hover-video.styles"

const HoverVideo = ({
  companyName,
  technologyDescription,
  image,
  videoUrl,
  caseStudyUrl,
}) => {
  const [opacity, setOpacity] = useState(false)

  if (!image) return null
  return (
    <S.CardContainer
    // onMouseOver={() => setOpacity(true)}
    // onMouseLeave={() => setOpacity(false)}
    >
      <S.Link url={caseStudyUrl} target="_blank">
        <S.BgImage
          loading="eager"
          img={image}
          alt="company"
          tag="div"
          // opacity={opacity && videoUrl ? "0" : "1"}
        >
          <S.ContentContainer>
            {companyName && <S.CompanyName>{companyName}</S.CompanyName>}
            <S.CategoriesContainer>
              {technologyDescription?.map(({ name }, index) => (
                <S.Technology key={`${index} - ${name}`}>
                  {index !== technologyDescription?.length - 1
                    ? `${name},`
                    : name}
                  &nbsp;
                </S.Technology>
              ))}
            </S.CategoriesContainer>
          </S.ContentContainer>
        </S.BgImage>
        {/*<S.CustomVideo playsInline autoPlay loop muted src={videoUrl} />*/}
      </S.Link>
    </S.CardContainer>
  )
}

export default HoverVideo
