import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"
import cursor from "../../assets/icons/plus-icon.svg"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 1rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 1rem;
  }
`
export const Wrapper = styled(Container)``
export const Title = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
  strong {
    color: ${({ theme }) => theme.palette.text.light};
    font-weight: 500;
  }
  margin-bottom: 4rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 2rem;
  }
`
export const CardsContainer = styled.div`
  //cursor: none;
`
export const CustomCursor = styled(cursor)`
  display: none;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: revert;
    width: 70px;
    height: 70px;
    position: fixed;
    z-index: 99;
    pointer-events: none;

    &.hoverOn {
      transform: rotate(90deg) scale(1.5);
      transition: transform 0.5s;
    }
    &.hoverOff {
      transform: rotate(-90deg) scale(0);
      transition: transform 0.5s;
    }
  }
`
