import React from "react"
import { Grid } from "@mui/material"
import HoverVideo from "../../components/hover-video/hover-video.component"
import FadeTitle from "../../components/fade-title/fade-title.component"

import * as S from "./help-transform-companies.styles"

const HelpTransformCompanies = ({ title, companies, link }) => {
  return (
    <S.Section>
      <S.Wrapper>
        {title && (
          <S.TitleWrapper>
            <FadeTitle title={title} isDark={false} />
          </S.TitleWrapper>
        )}
        <Grid container justifyContent="center" spacing={5}>
          {companies?.map(({ caseStudy }, index) => (
            <S.GridCard
              item
              xs={12}
              md={6}
              key={`${index} - ${caseStudy?.title}`}
            >
              <S.CardContainer>
                <S.Card className={(index + 1) % 2 === 0 && "move"}>
                  <HoverVideo
                    companyName={caseStudy?.title}
                    image={caseStudy?.caseStudiesBuilder?.coverCard?.image}
                    technologyDescription={
                      caseStudy?.caseStudyCategories?.nodes
                    }
                    caseStudyUrl={`/casos-de-estudio/${caseStudy?.slug}`}
                  />
                </S.Card>
              </S.CardContainer>
            </S.GridCard>
          ))}
        </Grid>
        {link && (
          <S.ButtonContainer>
            <S.Button href={link.url} className="lightBorder">
              {link.title}
            </S.Button>
          </S.ButtonContainer>
        )}
      </S.Wrapper>
    </S.Section>
  )
}

export default HelpTransformCompanies
