import React, { useContext, useEffect, useRef, useState } from "react"
import { Grid } from "@mui/material"
import CardLeader from "../../components/card-leader/card-leader.component"
import parse from "html-react-parser"
import useMousePosition from "../../hooks/use-mouse-position/use-mouse-position"

import * as S from "./team-leaders.styles"

const TeamLeaders = ({ title, teamCards }) => {
  const { x, y } = useMousePosition()
  const [limit, setLimit] = useState(false)

  const handleHoverOn = () => {
    setLimit(true)
  }
  const handleHoverOff = () => {
    setLimit(false)
  }

  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        <S.Title className="signifierMedium">{parse(title)}</S.Title>
        <S.CardsContainer
        // onMouseLeave={handleHoverOff}
        // onMouseEnter={handleHoverOn}
        >
          {/*<S.CustomCursor*/}
          {/*  style={{ left: `${x}px`, top: `${y}px` }}*/}
          {/*  className={limit ? "hoverOn" : "hoverOff"}*/}
          {/*/>*/}
          <Grid container spacing={3}>
            {teamCards?.map((item, index) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={12 / 5}
                key={index - item.name}
              >
                <CardLeader {...item} />
              </Grid>
            ))}
          </Grid>
        </S.CardsContainer>
      </S.Wrapper>
    </S.Section>
  )
}

export default TeamLeaders
