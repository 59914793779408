import React, { useRef } from "react"
import Lottie from "lottie-react"
import { cardServiceLogo } from "../../utils"

import * as S from "./card-service.styles"

const CardService = ({ serviceName, description }) => {
  const logoValues = cardServiceLogo(serviceName)
  const lottieRef = useRef()
  const handleHoverOn = () => {
    lottieRef.current.setDirection(1)
    lottieRef.current.playSegments([0, 50], true)
  }
  const handleHoverOff = () => {
    lottieRef.current.setDirection(-1)
    lottieRef.current.playSegments(lottieRef.current, true)
  }

  return (
    <S.CardContainer
      onMouseEnter={handleHoverOn}
      onMouseLeave={handleHoverOff}
      color={logoValues.logoColor}
    >
      <S.LottieContainer color={logoValues.bgColor}>
        <Lottie
          lottieRef={lottieRef}
          animationData={logoValues.logo}
          autoplay={false}
          loop={false}
        />
      </S.LottieContainer>
      <S.ServiceName>{serviceName}</S.ServiceName>
      <S.Description>{description}</S.Description>
    </S.CardContainer>
  )
}

export default CardService
