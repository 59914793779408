import styled from "styled-components"
export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
`
export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  //font-weight: 500;
  margin-bottom: 1rem;
  font-family: "SignifierMedium", sans-serif;
`
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  max-width: 270px;
`
