import React from "react"

import * as S from "./statistic-box.styles"

const StatisticBox = ({ title, description }) => {
  return (
    <S.CardContainer>
      <S.Title className="signifierMedium">{title}</S.Title>
      <S.Description>{description}</S.Description>
    </S.CardContainer>
  )
}

export default StatisticBox
