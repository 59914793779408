import React from "react"
import { Grid } from "@mui/material"
import CardService from "../../components/card-service/card-service.component"

import * as S from "./services.styles"

const Services = ({ services, link, title }) => {
  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        <S.Title className="signifierMedium">{title}</S.Title>
        <Grid container justifyContent="center" spacing={4}>
          {services.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={`${index} - ${item.serviceName}`}
            >
              <CardService {...item} />
            </Grid>
          ))}
        </Grid>
        <S.ButtonContainer>
          <S.Button href={link.url} className="lightBorder">
            {link.title}
          </S.Button>
        </S.ButtonContainer>
      </S.Wrapper>
    </S.Section>
  )
}

export default Services
